import { Authenticator, useTheme, View, Image, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';  
import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { signedIn, signedOut } from '../../features/auth/authSlice';

const signinUIComponents = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="FHL"
            src="/images/fhl.png"
          />
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      }
    }
};

interface AuthenticatorProps {
    children: JSX.Element | JSX.Element[];
}

const FHLAuthenticator = function({ children } : AuthenticatorProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          dispatch(signedIn({
            username: data.username,
            isAdmin: data.signInUserSession.idToken.payload['custom:isAdmin'] === 'true',
            userSub: data.sub,
            jwtToken: data.signInUserSession.idToken.jwtToken
          }));
          break
        case 'signOut':
          dispatch(signedOut());
          break
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Authenticator hideSignUp={true} components={signinUIComponents}>
        {({ signOut, user}) => {
            return (
              <>
              {children}
              </>
            )}}
    </Authenticator>
    );
}

export default FHLAuthenticator;