import { Route, Routes } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";

import { useAppDispatch, useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import { signedOut } from "./features/auth/authSlice";
import FHLAuthenticator from './components/FHLAuthenticator';
import FHLAppBar from './components/FHLAppBar';
import Construction from './pages/Construction';
import Owners from './pages/Admin/Owners';
import Teams from './pages/Admin/Teams';
import Players from './pages/Admin/Players';
import ScoringPeriods from './pages/Admin/ScoringPeriods';
import DraftPicksPage from "./pages/Admin/DraftPicks";
import TeamRoster from "./pages/Team/TeamRoster/TeamRoster";
import TeamPlayerStats from "./pages/Team/TeamPlayerStats";
import TeamStatsByPeriod from "./pages/Team/TeamStatsByPeriod";
import SkaterStatistics from "./pages/League/SkaterStatistics";
import GoalieStatistics from "./pages/League/GoalieStatistics";

const App = function () {
  const dispatch = useAppDispatch();

  const {isAdmin} = useAppSelector((state: RootState)=> state.auth);
 
  const pages = [
    {name: 'Your Team', 
      subPages: [{name: 'Roster', link: ''},
        {name: 'Player Stats', link: 'TeamPlayerStats'},
        {name: 'Team Stats', link: 'TeamStatsByPeriod'}
      ]}, 
    {name: 'Auction', link: 'Auction'}, 
    {name: 'League Statistics',
      subPages: [{name: 'Standings', link: 'Standings'},
        {name: 'Skater Statistics', link: 'SkaterStats'},
        {name: 'Goalie Statistics', link: 'GoalieStats'}
      ]},
    {name: 'League Administration', 
      subPages: [{name: 'Owners', link: 'Owners'},
        {name: 'Teams', link: 'Teams'},
        {name: 'Players', link: 'Players'},
        {name: 'Periods', link: 'ScoringPeriods'},
        {name: 'Draft Picks', link: 'DraftPicks'}
      ]},
  ];

  useBeforeunload(event => {
    dispatch(signedOut);
    localStorage.clear();
  });

  return (
    <FHLAuthenticator>
      <FHLAppBar pages={pages}>
        <Routes>
          <Route path="/" element={<TeamRoster/>}/>
          <Route path="/TeamPlayerStats" element={<TeamPlayerStats/>}/>
          <Route path="/TeamStatsByPeriod" element={<TeamStatsByPeriod/>}/>
          <Route path="/Owners" element={<Owners isAdmin={isAdmin}/>}/>
          <Route path="/Teams" element={<Teams isAdmin={isAdmin}/>}/>
          <Route path="/Players" element={<Players isAdmin={isAdmin}/>}/>
          <Route path="/ScoringPeriods" element={<ScoringPeriods isAdmin={isAdmin}/>}/>
          <Route path="/DraftPicks" element={<DraftPicksPage isAdmin={isAdmin}/>}/>
          <Route path="/SkaterStats" element={<SkaterStatistics/>}/>
          <Route path="/GoalieStats" element={<GoalieStatistics/>}/>
          <Route path="*" element={<Construction/>}/>
        </Routes>
      </FHLAppBar>
    </FHLAuthenticator>
  );
}

export default App;
