import { SyntheticEvent, useState } from "react";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabbedPanelProps {
    tabs: {title: string, panel: JSX.Element}[]
}

export default function TabbedPanel(props: TabbedPanelProps) {

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {props.tabs.map((tab, index) => (
                    <Tab label={tab.title} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {props.tabs.map((tab, index)=> (
                <TabPanel value={value} index={index}>
                    {tab.panel}
                </TabPanel>
            ))}
        </Box>
    );
}