import { useGetPlayersQuery, useGetTeamsQuery, useAddPlayerMutation, useSetPlayerTeamMutation } from "../../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../../components/DataTablePane/DataTablePane';
import { GridRenderCellParams } from "@mui/x-data-grid";

function calculateAge (birthDateString: string, otherDateString: string) {
    const birthDate = new Date(birthDateString);
    const otherDate = new Date(otherDateString);

    var years = (otherDate.getFullYear() - birthDate.getFullYear());

    if (otherDate.getMonth() < birthDate.getMonth() || 
        (otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())) {
        years--;
    }

    return years;
}

export default function Players(props: {isAdmin: boolean}) {
    const {data: players, error: playerError, isLoading: isPlayerLoading} = useGetPlayersQuery();
    const {data: teams, error: teamError, isLoading: isTeamLoading} = useGetTeamsQuery();

    const [addPlayer] = useAddPlayerMutation();
    const [changePlayerTeam] = useSetPlayerTeamMutation();

    const adminProps: AdminPaneProps = {
        data: players,
        error: [playerError, teamError],
        isLoading: isPlayerLoading || isTeamLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "New Player",
            textItems: [
                {name: "name", required: true, type: "string", label: "Player Name", defaultValue: ""}, 
                {name: "birthDate", required: true, type: "date", label: "Birthdate", defaultValue: ""},
                {name: "pos", required: true, type: "string", label: "Position", defaultValue: ""},
                {name: "nhlTeam", required: false, type: "string", label: "NHL Team", defaultValue: "" },
                {name: "inFHL", required: true, type: "string", label: "In FHL?", defaultValue: "Y"},
                {name: "hockeyDBid", required: false, type: "string", label: "HockeyDB Profile", defaultValue: ""},
                {name: "hockeyRefid", required: false, type: "string", label: "HockeyRef Profile", defaultValue: ""},
            ],
            submit: addPlayer
        },
        tableInfo: {
            columnInfo: [
                {definition: {headerName: "Player Name", field: 'name', flex: 4, minWidth: 200}, item: (item) => (item.name)},
                {definition: {headerName: "Birthdate", field: 'birthdate', flex: 2, minWidth: 100}, item: (item) => (item.birthDate)},
                {definition: {headerName: "U22?", field: 'u22', flex: 1, minWidth: 50}, 
                    item: (item) => (calculateAge(item.birthDate, '2022-09-01') <= 22 ? 'Y' : 'N')},
                {definition: {headerName: "Pos", field: 'pos', flex: 1, minWidth: 50}, item: (item) => (item.pos)},
                {definition: {headerName: "NHL", field: 'nhlTeam', flex: 1, minWidth: 50}, item: (item) => (item.nhlTeam)},
                {definition: {headerName: "FHL", field: 'fhlTeam', editable: props.isAdmin, flex: 1, minWidth: 50, type: 'singleSelect', 
                    valueOptions: teams && teams.map((team) => team.id)}, item: (item) => (item.fhlTeam), onChange: changePlayerTeam
                },
                {definition: {headerName: "In FHL?", field: 'inFHL', flex: 1, minWidth: 50}, item: (item) => (item.inFHL ? 'Y': 'N')},
                {definition: {headerName: "HockeyDB ID", field: 'hockeyDBid', flex: 2, minWidth: 100}, item: (item) => (item.hockeyDBid)},
                {definition: {headerName: "HockeyRef ID", field: 'hockeyRefid', flex: 2, minWidth: 100,
                    valueFormatter: ({value}) => value,
                    renderCell: (params: GridRenderCellParams<string>) => (
                        <a href={`https://www.hockey-reference.com/players/${params.value.charAt(0)}/${params.value}.html`} rel='noreferrer' target='_blank'>Hockey-Ref Link</a>
                    )}, item: (item) => (item.hockeyRefid)},
            ]
        }
    };

    return (
        <AdminPane {...adminProps}/>
    );

}