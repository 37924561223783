import { useGetDraftPicksQuery, useGetTeamsQuery, useSetDraftPickTeamMutation, useMakeDraftPicksForSeasonMutation, DraftPicks } from "../../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../../components/DataTablePane/DataTablePane';
import configData from '../../../config.json';
import TabbedPanel from "../../../components/TabbedPanel/TabbedPanel";

export default function DraftPicksPage(props: {isAdmin: boolean}) {
    const nextSeason = (parseInt(configData.currentSeason)+1).toString();
    const {data: currentPicks, error: currentPicksError, isLoading: isCurrentPicksLoading} = useGetDraftPicksQuery(configData.currentSeason);
    const {data: nextPicks, error: nextPicksError, isLoading: isNextPicksLoading} = useGetDraftPicksQuery(nextSeason);
    const {data: teams, error: teamError, isLoading: isTeamLoading} = useGetTeamsQuery();

    const [changePickTeam] = useSetDraftPickTeamMutation();
    const changePickTeamForCurrentSeason = (arg: {item: DraftPicks, team: string}) => {
        changePickTeam({item: {...arg.item, season: configData.currentSeason}, team: arg.team});
    };
    const changePickTeamForNextSeason = (arg: {item: DraftPicks, team: string}) => {
        console.log({...arg.item, season: nextSeason});
        changePickTeam({item: {...arg.item, season: nextSeason}, team: arg.team});
    };
    const [makeDraftPicks] = useMakeDraftPicksForSeasonMutation();

    const currentPicksProps: AdminPaneProps = {
        data: currentPicks,
        error: [currentPicksError, teamError],
        isLoading: isCurrentPicksLoading && isTeamLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "Generate New Draft Picks",
            textItems: [
                {name: "season", required: true, type: "string", label: "Season", defaultValue: configData.currentSeason}, 
                {name: "rounds", required: true, type: "number", label: "Rounds", defaultValue: "4"}, 
                ],
            submit: makeDraftPicks
        },
        tableInfo: {
            columnInfo: [
            {definition: {field: 'round', headerName: "Round", flex: 1, minWidth: 50}, item: (item) => (item.round)},
            {definition: {field: 'original', headerName: "Original Team", flex: 1, minWidth: 50}, item: (item) => (item.original)},
            {definition: {headerName: "Order", field: 'order', type: 'number', editable: props.isAdmin, flex: 1, minWidth: 50}, 
                item: (item) => (item.order), onChange: changePickTeam},
            {definition: {headerName: "Owning Team", field: 'team', editable: props.isAdmin, flex: 1, minWidth: 50, type: 'singleSelect', 
                valueOptions: teams && teams.map((team) => team.id)}, item: (item) => (item.owning), onChange: changePickTeamForCurrentSeason
            }
        ]}
    };

    const nextPicksProps: AdminPaneProps = {
        data: nextPicks,
        error: [nextPicksError, teamError],
        isLoading: isNextPicksLoading || isTeamLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "Generate New Draft Picks",
            textItems: [
                {name: "season", required: true, type: "string", label: "Season", defaultValue: configData.currentSeason}, 
                {name: "rounds", required: true, type: "number", label: "Rounds", defaultValue: "4"}, 
                ],
            submit: makeDraftPicks
        },
        tableInfo: {
            columnInfo: [
            {definition: {field: 'round', headerName: "Round", flex: 1, minWidth: 50}, item: (item) => (item.round)},
            {definition: {field: 'original', headerName: "Original Team", flex: 1, minWidth: 50}, item: (item) => (item.original)},
            {definition: {headerName: "Order", field: 'order', type: 'number', editable: props.isAdmin, flex: 1, minWidth: 50}, 
                item: (item) => (item.order), onChange: changePickTeam},
            {definition: {headerName: "Owning Team", field: 'team', editable: props.isAdmin, flex: 1, minWidth: 50, type: 'singleSelect', 
                valueOptions: teams && teams.map((team) => team.id)}, item: (item) => (item.owning), onChange: changePickTeamForNextSeason
            }
        ]}
    };

    const tabs = [
        {title: "Current Season", panel: (<AdminPane {...currentPicksProps}/>) },
        {title: "Next Season", panel: (<AdminPane {...nextPicksProps}/>)}
    ];

    return (<TabbedPanel tabs={tabs}/>);
}