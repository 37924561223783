import { ScoringPeriod, useAddPeriodMutation, useGetScoringPeriodsQuery } from "../../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../../components/DataTablePane/DataTablePane';

export default function ScoringPeriods(props: {isAdmin: boolean}) {
    const {data, error, isLoading} = useGetScoringPeriodsQuery();
    const [addPeriod] = useAddPeriodMutation();
    const adminProps: AdminPaneProps = {
        data,
        error: [error],
        isLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "New Period",
            textItems: [
                {name: "period", required: true, type: "string", label: "ID", defaultValue: ""}, 
                {name: "start", required: true, type: "date", label: "Start Date", defaultValue: ""}, 
                {name: "end", required: true, type: "date", label: "End Date", defaultValue: ""},
            ],
            submit: addPeriod
        },
        tableInfo: {
            columnInfo: [
                {definition: {headerName: "Period", field: 'period', flex: 1, minWidth: 25}, item: (item) => (item.period)},
                {definition: {headerName: "Start", field: 'start', flex: 4, minWidth: 100}, item: (item: ScoringPeriod) => (item.start)},
                {definition: {headerName: "End", field: 'end', flex: 4, minWidth: 100}, item: (item: ScoringPeriod) => (item.end)},
            ]
        }
    };

    return (
        <AdminPane {...adminProps}/>
    );
}