import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthInfo {
  jwtToken: string,
  username: string,
  isAdmin: boolean,
  userSub: string,
}

export const authSlice = createSlice({
    name: 'auth',
    reducers: {
      signedIn: (state, action:PayloadAction<AuthInfo>) => {
        state.jwtToken = action.payload.jwtToken;
        state.username = action.payload.username;
        state.isAdmin =  action.payload.isAdmin;
        state.userSub = action.payload.userSub;

      },
      signedOut: (state) => {
        state.jwtToken = "";
        state.username = "";
        state.isAdmin =  false;
        state.userSub = "";
      }
    },
    initialState: {
      jwtToken: "",
      username: "",
      isAdmin: false,
      userSub: "",
    } as AuthInfo
  });

export const { signedIn, signedOut } = authSlice.actions;

export default authSlice.reducer;
  
  