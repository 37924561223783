import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Amplify from '@aws-amplify/core';

import './index.css';
import configData from './config.json';
import App from './App';
import { store, persistor } from './app/store';
import * as serviceWorker from './serviceWorker';

Amplify.configure({
  Auth: {
    userPoolId: configData.userPoolId,
    region: configData.region,
    userPoolWebClientId: configData.userPoolWebClientId
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
