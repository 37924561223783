import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../app/store';
import configData from '../../config.json';

export interface SkaterStats {
    gp: number,
    goals: number,
    assists: number,
    pim: number,
    plusMinus: number,
    ppg: number,
    shg: number,
    gwg: number,
    fow: number,
    fol: number,
    shots: number,
    blocks: number,
    hits: number,
    take: number,
    give: number,
    toi: number
}

export interface GoalieStats {
    gp: number,
    toi: number,
    wins: number,
    losses: number,
    ties: number,
    ga: number,
    sa: number,
    so: number,
    goals: number,
    assists: number,
    pim: number,
}

export interface PlayerStats {
  hockeyRef: string,
  name: string,
  pos: string,
  stats: SkaterStats | GoalieStats,
}

export interface Team {
  id: string,
  name: string,
  balance: number,
  players?: RosterPlayer[][],
  statPeriods?: StatPeriod[]
}

export interface DraftPicks {
  original: string,
  owning: string,
  round: number,
  season: string,
  player? : Player,
  order? : number
}

export interface RosterPlayer {
  player: Player,
  active: boolean
}

export interface StatPeriod {
  periodId: string,
  goals: number,
  assists: number,
  toughness: number,
  dstat: number,
  gstat: number,
  wins: number,
  losses: number,
  ties: number
}

export interface Player {
  id: number,
  name: string,
  birthYear: string,
  pos: string,
  inFHL: boolean,
  nhlTeam: string,
  hockeyDBid: string,
  hockeyRefid: string,
  contract: string,
  salary: string,
  stats: SkaterStats | GoalieStats
}

export interface ScoringPeriod {
  periodId: string,
  start: string,
  end: string,
}

export interface Owner {
  username: string,
  name: string,
  email: string,
  team? : string,
}

interface OwnerTeam {
  username: string,
  team: string,
  season? : string
}

export const statsAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://rl10jg367f.execute-api.us-west-2.amazonaws.com/v1/',
    prepareHeaders: (headers, {getState}) => { 
      const {auth} = getState() as RootState;
      headers.set("Authorization", auth.jwtToken);
      headers.set("Access-Control-Allow-Origin", "*"); 
      return headers;}
  }),
  tagTypes: ['PlayerStats', 'Teams', 'Players', 'ScoringPeriods', 'Owners', 'DraftPicks'],
  endpoints: (build) => ({

    // Retrieve latest Player Stats, Retrieve Player Stats for a given period
    getPlayerStats: build.query<PlayerStats[], void>({
      query: () => 'playerstats',
      providesTags: (result) => result ? 
         [
          ...result.map((playerStat) => ({ type: 'PlayerStats', id: playerStat.hockeyRef } as const)),
          { type: 'PlayerStats', id: 'LIST' },
        ]
      : 
        [{ type: 'PlayerStats', id: 'LIST' }],
    }),

    // Get All Teams, Add a Team, Modify a Team, Get Team for User
    getTeams: build.query<Team[], void>({
      query: () => 'teams',
      providesTags: (result) => result ? 
         [
          ...result.map((team) => ({ type: 'Teams', id: team.id } as const)),
          { type: 'Teams', id: 'LIST' },
        ]
      : 
        [{ type: 'Teams', id: 'LIST' }],
    }),
    addTeam: build.mutation<Team, Team>({
      query: (team: Team) => ({
        url: 'teams',
        method: 'POST',
        body: {id: team.id, name: team.name, balance: team.balance}
      }),
      invalidatesTags: [{type: 'Teams', id: 'LIST'}],
    }),
    getTeamForUser: build.query<Team|undefined, {username: string, season: string}>({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const ownerResult = await fetchWithBQ(`owners/${arg.username}`);
        if (ownerResult.error) return {error: ownerResult.error};
        const data = ownerResult.data as {Owner: Owner[], OwnerTeam: OwnerTeam[]}
        if (!data) {
          return {error: { status: "FETCH_ERROR", error: "No owner given for username."}};
        }
        const team = data.OwnerTeam.find(
          (ownerTeam) => ownerTeam.username === data.Owner[0].username && ownerTeam.season === configData.currentSeason)?.team
        const result = await fetchWithBQ(`teams/${team}`);
        return result.data ? { data: result.data as Team } :
          {error: result.error as FetchBaseQueryError };
      },
      providesTags: (result, _error, meta) => result ? [{ type: 'Owners', id: meta.username }] : [],
    }),

    // Get All Players, Get a specific Player, Add a Player, Modify a Player
    getPlayers: build.query<Player[], void>({
      query: () => 'players',
      providesTags: (result) => result ? 
        [
          ...result.map((player) => ({ type: 'Players', id: player.id } as const)),
          { type: 'Players', id: 'LIST' },
        ]
      : 
        [{ type: 'Players', id: 'LIST' }],
    }),
    addPlayer: build.mutation<Player, Player>({
      query: (player: Player) => ({
        url: 'players',
        method: 'POST',
        body: player
      }),
      invalidatesTags: [{type: 'Players', id: 'LIST'}],
    }),
    
    setPlayerTeam: build.mutation<void, {item: Player, team: string}>({
      query: (args: {item: Player, team: string}) => ({
        url: `roster/${args.team}`,
        method: 'PUT',
        body: {playerId: args.item.id, active: false}
      }),
    }),


    // Get All Owners, Add an Owner, Modify an Owner, Change the Team for an Owner
    getOwners: build.query<Owner[], void>({
      query: () => 'owners',
      providesTags: (result) => result ? 
        [
          ...result.map((owner) => ({ type: 'Owners', id: owner.username } as const)),
          { type: 'Owners', id: 'LIST' },
        ]
      : 
        [{ type: 'Owners', id: 'LIST' }],
      transformResponse: (response: { Owner: Owner[], OwnerTeam: OwnerTeam[] }) => {
        return response.Owner.map((owner) => {
          return {...owner, team: response.OwnerTeam.find(
            (ownerTeam) => ownerTeam.username === owner.username && ownerTeam.season === configData.currentSeason)?.team};
        })
      }
    }),
    addOwner: build.mutation<Owner, Owner>({
      query: (owner: Owner) => ({
        url: 'owners',
        method: 'POST',
        body: owner
      }),
      invalidatesTags: [{type: 'Owners', id: 'LIST'}],
    }),
    setOwnerTeam: build.mutation<Owner, {item: Owner, team: string}>({
      query: (args: {item: Owner, team: string}) => ({
        url: `owners/${args.item.username}`,
        method: 'PUT',
        body: {...args.item, team: args.team}
      }),
      transformResponse: (response: Owner, meta, arg) => ({team: arg.team, ...response}),
      invalidatesTags: (owner) => (owner ? [{ type: 'Owners', id: owner.username}, {type: 'Owners', id: 'LIST'}] : []),
    }),

    // Get All Scoring Periods, Create a Scoring Period, Modify a Scoring Period
    getScoringPeriods: build.query<ScoringPeriod[], void>({
      query: () => 'periods',
      providesTags: (result) => result ? 
        [
          ...result.map((period) => ({ type: 'ScoringPeriods', id: period.periodId } as const)),
          { type: 'ScoringPeriods', id: 'LIST' },
        ]
      : 
        [{ type: 'ScoringPeriods', id: 'LIST' }],
    }),
    addPeriod: build.mutation<ScoringPeriod, ScoringPeriod>({
      query: (period: ScoringPeriod) => ({
        url: 'periods',
        method: 'POST',
        body: period
      }),
      invalidatesTags: [{type: 'ScoringPeriods', id: 'LIST'}],
    }),

    // Get Draft Picks for a Team, Change Draft Pick, Get Draft Picks for a Round
    getDraftPicks: build.query<DraftPicks[], string>({
      query: (season: string) => `picks?season=${season}`,
      providesTags: (result, _error, arg) => result ? 
        [
          ...result.map((pick) => ({ type: 'DraftPicks', id: pick.season + pick.original + pick.round } as const)),
          { type: 'DraftPicks', id: arg },
        ]
      : 
        [{ type: 'DraftPicks', id: arg }],
    }),
    getDraftPicksForTeam: build.query<DraftPicks[], {team: string, season: string}>({
      query: (args: {team: string, season: string}) => `picks/${args.team}?season=${args.season}`,
      providesTags: (result, _error, arg) => result ? 
        [
          ...result.map((pick) => ({ type: 'DraftPicks', id: pick.season + pick.original + pick.round } as const)),
          { type: 'DraftPicks', id: arg.season },
        ]
      : 
        [{ type: 'DraftPicks', id: arg.season }],
    }),
    makeDraftPicksForSeason: build.mutation<DraftPicks, {season: string, rounds:number}>({
      query: (args: {season: string, rounds:number}) => ({
        url: 'picks',
        method: 'POST',
        body: JSON.stringify({season: args.season, rounds: args.rounds}),
      }),
      invalidatesTags: (result) => (result ? [{type: 'DraftPicks', id: result.season}]: []),
    }),
    setDraftPickTeam: build.mutation<DraftPicks, {item: DraftPicks, team: string}>({
      query: (args: {item: DraftPicks, team: string}) => ({
        url: `picks/${args.item.round}/${args.item.original}`,
        method: 'PUT',
        body: {...args.item, owning: args.team}
      }),
      invalidatesTags: (draftPick) => (draftPick ? 
        [{ type: 'DraftPicks', id: draftPick.season + draftPick.original + draftPick.round}, 
        {type: 'DraftPicks', id: draftPick.season}] : []),
    }),
  }),
})

export const { 
  useGetPlayerStatsQuery, 
  useGetTeamsQuery, 
  useGetTeamForUserQuery,
  useGetOwnersQuery, 
  useGetPlayersQuery, 
  useGetScoringPeriodsQuery,
  useGetDraftPicksQuery,
  useGetDraftPicksForTeamQuery,
  useSetDraftPickTeamMutation,
  useAddTeamMutation,
  useAddOwnerMutation,
  useAddPlayerMutation,
  useAddPeriodMutation,
  useSetOwnerTeamMutation,
  useSetPlayerTeamMutation,
  useMakeDraftPicksForSeasonMutation,
 } = statsAPI