import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetTeamForUserQuery } from '../../../features/stats/statsAPI';
import TeamHeader from '../../../components/TeamHeader/TeamHeader';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import configData from '../../../config.json';

export default function TeamStatsByPeriod() {
  const {username} = useAppSelector((state: RootState)=> state.auth);
  const { data: team } = useGetTeamForUserQuery({username, season: configData.currentSeason}, {skip: username === ""});
  return (
    <>
    <TeamHeader selectedTeam={team}/>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Scoring Period</TableCell>
            <TableCell align="right">Goals</TableCell>
            <TableCell align="right">Assists</TableCell>
            <TableCell align="right">Toughness</TableCell>
            <TableCell align="right">DStat</TableCell>
            <TableCell align="right">GStat</TableCell>
            <TableCell align="right">Wins</TableCell>
            <TableCell align="right">Losses</TableCell>
            <TableCell align="right">Ties</TableCell>
            <TableCell align="right">Pts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team?.statPeriods && team.statPeriods.map((statPeriod: any) => (
            <TableRow
            key={statPeriod.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {statPeriod.number}
            </TableCell>
            <TableCell align="right">{statPeriod.goals}</TableCell>
            <TableCell align="right">{statPeriod.assists}</TableCell>
            <TableCell align="right">{statPeriod.toughness}</TableCell>
            <TableCell align="right">{statPeriod.dstat}</TableCell>
            <TableCell align="right">{statPeriod.gstat}</TableCell>
            <TableCell align="right">{statPeriod.wins}</TableCell>
            <TableCell align="right">{statPeriod.losses}</TableCell>
            <TableCell align="right">{statPeriod.ties}</TableCell>
            <TableCell align="right">{2*statPeriod.wins+statPeriod.ties}</TableCell>
          </TableRow>
          ))}
          { team?.statPeriods && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">TOTALS</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.goals)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.assists)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.toughness)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.dstat)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.gstat)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.wins)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.losses)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (statPeriod.ties)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
          <TableCell align="right">{team.statPeriods.map((statPeriod:any) => (2* statPeriod.wins + statPeriod.ties)).reduce((acc:number,cur:number)=>(acc+cur),0)}</TableCell>
        </TableRow> )}
      </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}