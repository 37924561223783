import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetTeamForUserQuery, Team } from '../../../features/stats/statsAPI';
import TeamHeader from '../../../components/TeamHeader/TeamHeader';
import configData from '../../../config.json';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import TabbedPanel from "../../../components/TabbedPanel/TabbedPanel";

function RosterTable(props: {team: Team|undefined}) {
  return (
  <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Player Name</TableCell>
        <TableCell align="right">Active</TableCell>
        <TableCell align="right">Salary</TableCell>
        <TableCell align="right">Contract</TableCell>
        <TableCell align="right">Position</TableCell>
        <TableCell align="right">NHL Team</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.team?.players && props.team.players.map((player: any) => (
        <TableRow
        key={player.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {player.name}
        </TableCell>
        <TableCell align="right">{player.active ? "": "R"}</TableCell>
        <TableCell align="right">{player.salary}</TableCell>
        <TableCell align="right">{player.contract}</TableCell>
        <TableCell align="right">{player.position}</TableCell>
        <TableCell align="right">{player.nhl}</TableCell>
      </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
  );
}

export default function TeamRoster() {
  const {username} = useAppSelector((state: RootState)=> state.auth);
  const { data: team } = useGetTeamForUserQuery({username, season: configData.currentSeason}, {skip: username === ""});

  const tabs = [
    {title: "Current Period", panel: (<RosterTable team={team}/>)},
    {title: "Next Period", panel: (<RosterTable team={team}/>)}
  ];

  return (
    <>
    <TeamHeader selectedTeam={team}/>
    <TabbedPanel tabs={tabs}/>
    </>
  );
}