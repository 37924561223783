import { useGetPlayerStatsQuery } from "../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../components/DataTablePane/DataTablePane';

export default function SkaterStatistics() {
    const {data, error, isLoading} = useGetPlayerStatsQuery();

    const adminProps: AdminPaneProps = {
        data: data && data.filter((playerStat) => (playerStat.pos !== 'G')),
        error: [error],
        isLoading,
        isAdmin: false,
        tableInfo: {
            columnInfo: [
            {definition: {field: 'name', headerName: "Player Name", flex: 4, minWidth: 200}, item: (item) => (item.name)},
            {definition: {field: 'pos', headerName: "Pos", flex: 1, minWidth: 50}, item: (item) => (item.pos)},
            {definition: {field: 'gp', headerName: "GP", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.gp)},
            {definition: {field: 'toi', headerName: "TOI", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.toi)},
            {definition: {field: 'goals', headerName: "G", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.goals)},
            {definition: {field: 'assists', headerName: "A", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.assists)},
            {definition: {field: 'points', headerName: "Pts", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.goals + item.stats.assists)},
            {definition: {field: 'pim', headerName: "PIM", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.pim)},
            {definition: {field: 'hits', headerName: "Hits", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.hits)},
            {definition: {field: 'blks', headerName: "Blk", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.blocks)},
            {definition: {field: 'take', headerName: "TkA", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.take)},
            {definition: {field: 'give', headerName: "GvA", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.give)},
            {definition: {field: 'toughness', headerName: "Tough", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.pim + item.stats.hits)},
            {definition: {field: 'dstat', headerName: "DStat", type: "number", flex: 1, minWidth: 50, 
                valueFormatter: (params) => ((params.value as number).toFixed(2))}, 
                item: (item) => ((item.stats.blocks + item.stats.take - item.stats.give + item.stats.toi / (item.pos === 'F' ? 30 : 20))/4)},
        ]}
    };

    return (
        <AdminPane {...adminProps}/>
    );
}