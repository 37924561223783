import { Controller, FieldValues, Path } from "react-hook-form";
import { TextField } from "@mui/material";

type FieldPath = Path<FieldValues>;

export interface TextFieldProps {
  name: FieldPath;
  label: string;
  type: string;
  defaultValue: string;
  required: boolean;
}

interface FormInputProps {
  control: any;
  fieldInfo: TextFieldProps
}

export const FormTextField = (props: FormInputProps) => {
  return (
    <Controller
      name={props.fieldInfo.name}
      control={props.control}
      defaultValue={props.fieldInfo.defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          type={props.fieldInfo.type}
          label={props.fieldInfo.label}
          name={props.fieldInfo.label}
          id={props.fieldInfo.label}
          required={props.fieldInfo.required}
          margin="normal"
          InputLabelProps={props.fieldInfo.type === 'date' ? {shrink: true} : {}}
        />
      )}
    />
  );
};