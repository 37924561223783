import { useGetPlayerStatsQuery } from "../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../components/DataTablePane/DataTablePane';

export default function GoalieStatistics() {
    const {data, error, isLoading} = useGetPlayerStatsQuery();

    const adminProps: AdminPaneProps = {
        data: data && data.filter((playerStat) => (playerStat.pos === 'G')),
        error: [error],
        isLoading,
        isAdmin: false,
        tableInfo: {
            columnInfo: [
            {definition: {field: 'name', headerName: "Player Name", flex: 4, minWidth: 200}, item: (item) => (item.name)},
            {definition: {field: 'gp', headerName: "GP", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.gp)},
            {definition: {field: 'mins', headerName: "Mins", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.toi)},
            {definition: {field: 'record', headerName: "W-L-T", flex: 1, minWidth: 50}, 
                item: (item) => (`${item.stats.wins}-${item.stats.losses}-${item.stats.ties}`)},
            {definition: {field: 'so', headerName: "SO", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.so)},
            {definition: {field: 'ga', headerName: "GA", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.ga)},
            {definition: {field: 'sa', headerName: "SA", type: "number", flex: 1, minWidth: 50}, item: (item) => (item.stats.sa)},
            {definition: {field: 'svpct', headerName: "SVPCT", type: "number", flex: 1, minWidth: 50,
            valueFormatter: (params) => ((params.value as number).toLocaleString("en", {style: "percent", minimumFractionDigits: 2}))}, 
                item: (item) => (item.stats.sa === 0 ? 0 : (1 - item.stats.ga / item.stats.sa))},
            {definition: {field: 'gstat', headerName: "GStat", type: "number", flex: 1, minWidth: 50,
                valueFormatter: (params) => ((params.value as number).toFixed(2))},
                item: (item) => (2* item.stats.wins + item.stats.ties + 2 * item.stats.so + 0.15 * item.stats.sa - item.stats.ga)},
        ]}
    };

    return (
        <AdminPane {...adminProps}/>
    );
}