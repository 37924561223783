import { useAddOwnerMutation, useGetOwnersQuery, useGetTeamsQuery, useSetOwnerTeamMutation } from "../../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../../components/DataTablePane/DataTablePane';

export default function Owners(props: {isAdmin: boolean}) {
    const {data: owners, error: ownerError, isLoading: isOwnerLoading} = useGetOwnersQuery();
    const {data: teams, error: teamError, isLoading: isTeamLoading} = useGetTeamsQuery();

    const [addOwner] = useAddOwnerMutation();
    const [changeOwnerTeam] = useSetOwnerTeamMutation();

    const adminProps: AdminPaneProps = {
        data: owners,
        error: [ownerError, teamError],
        isLoading: isOwnerLoading || isTeamLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "New Owner",
            textItems: [
                {name: "username", required: true, type: "string", label: "Username", defaultValue: ""}, 
                {name: "name", required: true, type: "string", label: "Name", defaultValue: ""}, 
                {name: "email", required: true, type: "email", label: "Email Address", defaultValue: ""},
                ],
            submit: addOwner
        },
        tableInfo: {
            columnInfo: [
            {definition: {field: 'username', headerName: "Username", flex: 1, minWidth: 100}, item: (item) => (item.username)},
            {definition: {headerName: "Name", field: 'name', flex: 2, minWidth: 200}, item: (item) => (item.name)},
            {definition: {headerName: "Email", field: 'email', flex: 2, minWidth: 200}, item: (item) => (item.email)},
            {definition: {headerName: "Team", field: 'team', editable: props.isAdmin, flex: 0.5, minWidth: 50, type: 'singleSelect', 
                valueOptions: teams && teams.map((team) => team.id)}, item: (item) => (item.team), onChange: changeOwnerTeam
            }
        ]}
    };

    return (
        <AdminPane {...adminProps}/>
    );
}