import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import configData from '../../config.json';
import { Team, useGetDraftPicksForTeamQuery } from '../../features/stats/statsAPI';

export default function TeamHeader(props:{selectedTeam: Team|undefined}) {

  const { data: currentDraftPicks } = useGetDraftPicksForTeamQuery(
      props.selectedTeam ? {team: props.selectedTeam.id, season: configData.currentSeason} : skipToken );
  const { data: nextDraftPicks } = useGetDraftPicksForTeamQuery(
      props.selectedTeam ? {team: props.selectedTeam!.id, season: (parseInt(configData.currentSeason)+1).toString()} : skipToken);
  
  return (
    <>
    {props.selectedTeam === undefined && <LinearProgress/>}
    <Box sx={{ width: '100%' }}>
        <List sx={{width: '100%',bgcolor: 'background.paper'}}>
          <ListItem key="name">
              <ListItemText primary={props.selectedTeam && props.selectedTeam.name} />
          </ListItem>
          <ListItem key="balance">
              <ListItemText primary={"Balance: " + 
                (props.selectedTeam && new Intl.NumberFormat ("en-CA", { style: "currency", currency:"CAD" }).format(
                  props.selectedTeam.balance))} />
          </ListItem>
          <ListItem key="currentDraft">
              <ListItemText primary={"Current Year: " + 
                (currentDraftPicks ? 
                  currentDraftPicks.slice().sort((picka, pickb) => ((picka.round.toString()+picka.original).localeCompare(pickb.round.toString()+pickb.original)))
                  .reduce((prev, pick) => `${prev} ${(pick.original + pick.round)}`, '') : "Loading...") } />
          </ListItem>
          <ListItem key="nextDraft">
              <ListItemText primary={"Next Year: " + 
                (nextDraftPicks ?
                  nextDraftPicks.slice().sort((picka, pickb) => ((picka.round.toString()+picka.original).localeCompare(pickb.round.toString()+pickb.original)))
                  .reduce((prev, pick) => `${prev} ${(pick.original + pick.round)}`, '') : "Loading...") } />
          </ListItem>
        </List>
    </Box>
    </>
  );
}