import { useAddTeamMutation, useGetTeamsQuery } from "../../../features/stats/statsAPI";
import { AdminPane, AdminPaneProps } from '../../../components/DataTablePane/DataTablePane';

export default function Teams(props: {isAdmin: boolean}) {
    const {data, error, isLoading} = useGetTeamsQuery();
    const [ addTeam ] = useAddTeamMutation();

    const adminProps: AdminPaneProps = {
        data,
        error: [error],
        isLoading,
        isAdmin: props.isAdmin,
        dialogInfo: {
            title: "New Team",
            textItems: [
                {name: "id", required: true, type: "string", label: "Team ID", defaultValue: ""}, 
                {name: "name", required: true, type: "string", label: "Team Name", defaultValue: ""}, 
                {name: "balance", required: false, type: "string", label: "Starting Balance", defaultValue: ""},
            ],
            submit: addTeam
        },
        tableInfo: {
            columnInfo: [
                {definition: {headerName: "Abbr", field: 'abbr', flex: 1, minWidth: 50}, item: (item) => (item.id)},
                {definition: {headerName: "Name", field: 'name', flex: 4, minWidth: 200}, item: (item) => (item.name)},
                {definition: {headerName: "Balance", field: 'balance', flex: 2, minWidth: 100, type: 'number',
                    valueFormatter: (params) => (new Intl.NumberFormat('en-CA', {style: 'currency', currency: 'CAD'}).format(params.value as number))}, 
                    item: (item) => (item.balance)},
            ]
        }
    };

    return (
        <AdminPane {...adminProps}/>
    );
}