import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { useState, useCallback } from 'react';
import { TextFieldProps, FormTextField } from './FormTextField';

export interface DialogProps {
  title: string;
  textItems: TextFieldProps[];
  submit: (values: any) => void;
}

export const FormDialog = (props: DialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit, control, reset } = useForm();

  const submitFunction = props.submit;

  const onSubmit = useCallback((values) => {
    submitFunction(values);
    reset();
    handleClose();
  }, [reset, submitFunction]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
       {props.title}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {props.textItems.map((item, index) => (
            <FormTextField key={index} {...{control, fieldInfo: {...item}}}/>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}