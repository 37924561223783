import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetTeamForUserQuery, Team, SkaterStats, GoalieStats } from '../../../features/stats/statsAPI';
import TeamHeader from '../../../components/TeamHeader/TeamHeader';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import configData from '../../../config.json';
import TabbedPanel from "../../../components/TabbedPanel/TabbedPanel";

function SkaterStatsTable(props: {team: Team|undefined, onlyActive: boolean}) {

    // In real life, we'd need to have the current period
    return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Skater Name</TableCell>
            <TableCell align="right">GP</TableCell>
            <TableCell align="right">TOI</TableCell>
            <TableCell align="right">Goals</TableCell>
            <TableCell align="right">Assists</TableCell>
            <TableCell align="right">Points</TableCell>
            <TableCell align="right">PIM</TableCell>
            <TableCell align="right">Hits</TableCell>
            <TableCell align="right">Tough</TableCell>
            <TableCell align="right">Blocks</TableCell>
            <TableCell align="right">Takes</TableCell>
            <TableCell align="right">Gives</TableCell>
            <TableCell align="right">DStat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.team?.players && props.team.players[0].filter((player) => player.player.pos !== 'G' && (player.active || !props.onlyActive))
            .map((player) => {
            const skaterStats = player.player.stats as SkaterStats;
            return (
            <TableRow
            key={player.player.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {player.player.name}
            </TableCell>
            <TableCell align="right">{skaterStats.gp}</TableCell>
            <TableCell align="right">{skaterStats.toi}</TableCell>
            <TableCell align="right">{skaterStats.goals}</TableCell>
            <TableCell align="right">{skaterStats.assists}</TableCell>
            <TableCell align="right">{skaterStats.goals + skaterStats.assists}</TableCell>
            <TableCell align="right">{skaterStats.pim}</TableCell>
            <TableCell align="right">{skaterStats.hits}</TableCell>
            <TableCell align="right">{skaterStats.pim + skaterStats.hits}</TableCell>
            <TableCell align="right">{skaterStats.blocks}</TableCell>
            <TableCell align="right">{skaterStats.take}</TableCell>
            <TableCell align="right">{skaterStats.give}</TableCell>
            <TableCell align="right">{(skaterStats.toi / (player.player.pos === 'F' ? 30: 20) + 
              skaterStats.blocks + skaterStats.take - skaterStats.give)/4}</TableCell>
          </TableRow>
          );})}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function GoalieStatsTable(props: {team: Team|undefined, onlyActive: boolean}) {

  // In real life, we'd need to have the current period
  return (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Goalie Name</TableCell>
          <TableCell align="right">GP</TableCell>
          <TableCell align="right">Mins</TableCell>
          <TableCell align="right">Record</TableCell>
          <TableCell align="right">SO</TableCell>
          <TableCell align="right">GA</TableCell>
          <TableCell align="right">Shots</TableCell>
          <TableCell align="right">SVPCT</TableCell>
          <TableCell align="right">GStat</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.team?.players && props.team.players[0].filter((player) => player.player.pos === 'G' && (player.active || !props.onlyActive))
          .map((player) => {
          const goalieStats = player.player.stats as GoalieStats;
          return (
          <TableRow
          key={player.player.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {player.player.name}
          </TableCell>
          <TableCell align="right">{goalieStats.gp}</TableCell>
          <TableCell align="right">{goalieStats.toi}</TableCell>
          <TableCell align="right">{`${goalieStats.wins}-${goalieStats.losses}-${goalieStats.ties}`}</TableCell>
          <TableCell align="right">{goalieStats.so}</TableCell>
          <TableCell align="right">{goalieStats.ga}</TableCell>
          <TableCell align="right">{goalieStats.sa}</TableCell>
          <TableCell align="right">{goalieStats.sa === 0 ? 0 : (1 - goalieStats.ga / goalieStats.sa)}</TableCell>
          <TableCell align="right">{2* goalieStats.wins + goalieStats.ties + 2 * goalieStats.so + 0.15 * goalieStats.sa - goalieStats.ga}</TableCell>
        </TableRow>
        );})}
      </TableBody>
    </Table>
  </TableContainer>
);
}

export default function TeamPlayerStats() {
  const {username} = useAppSelector((state: RootState)=> state.auth);
  const { data: team } = useGetTeamForUserQuery({username, season: configData.currentSeason}, {skip: username === ""});

  const tabs = [
    {title: "Current Period", panel: (<><SkaterStatsTable team={team} onlyActive={true}/><GoalieStatsTable team={team} onlyActive={true}/></>)},
    {title: "Overall", panel: (<><SkaterStatsTable team={team} onlyActive={false}/><GoalieStatsTable team={team} onlyActive={false}/></>)}
  ];

  return (
    <>
    <TeamHeader selectedTeam={team}/>
    <TabbedPanel tabs={tabs}/>
    </>
  );
}