import { DataGrid, GridOverlay, GridToolbarExport, GridToolbarFilterButton, GridToolbarContainer, GridRowsProp, GridColDef, GridCellEditCommitParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { FormDialog, DialogProps } from '../FormDialog/FormDialog';

export interface AdminPaneProps {
    data: any[]|undefined;
    error: any[];
    isLoading: boolean;
    isAdmin: boolean;
    tableInfo: {
        columnInfo: TableColumnProps[]; 
    };   
    dialogInfo?: DialogProps;
    children?: JSX.Element | JSX.Element[];
}

interface TableColumnProps {
    definition: GridColDef;
    item: (item: any) => number|string|JSX.Element;
    onChange?: any
}

export function AdminPane(props: AdminPaneProps) {

    let errMsg = props.error.reduce((prev, error) => (
        error ? prev + <br/> + (error.status && (error.error || JSON.stringify(error.data))) || error.message : prev
    ), "");

    const rows: GridRowsProp = props.data ?
        props.data.map((item, index) => {
            return props.tableInfo.columnInfo.reduce((prev : {[key:string]: any}, columnInfo) => {
                let columnMap : {[key:string]: any} = {...prev};
                columnMap[columnInfo.definition.field] = columnInfo.item(item);
                return columnMap;
            }, {id: index});
        }) : [];

    const cols: GridColDef[] = props.tableInfo.columnInfo.map((columnInfo) => columnInfo.definition);

    const handleCellEditCommit = async (params: GridCellEditCommitParams) => {
        try {
            const mutateRow = props.tableInfo.columnInfo.find((columnInfo) => columnInfo.definition.field === params.field)?.onChange;
            if (mutateRow) {
                let itemToMutate = rows.find((row: {[key:string]: any})=> row.id === params.id);
                await mutateRow({
                    item: itemToMutate,
                    [params.field]: params.value,
                });
            }
        } catch (error) {
        // Restore the row in case of error
        console.log(error);
        }
    };

    function NoRowsLoadingOrNoneOverlay() {
        return (
            <GridOverlay>
                <Box sx={{ mt:1 }}>{props.isLoading ? 'Loading...' : 'No Rows'}</Box>
            </GridOverlay>
        );
    }

    function ExportToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    return (
        <>
        {props.isLoading  && <LinearProgress/>}
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
        <Box sx={{ display: 'flex', height: '80vh' }}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <DataGrid autoPageSize components={{Toolbar: ExportToolbar, NoRowsOverlay: NoRowsLoadingOrNoneOverlay,}} 
                        rows={rows} columns={cols} onCellEditCommit={handleCellEditCommit}/>
                </Grid>
                <Grid item xs={2}>
                    {props.isAdmin && props.dialogInfo && <FormDialog {...props.dialogInfo} />}
                    {props.children}
                </Grid>
            </Grid>
        </Box>
        </>
    );
}