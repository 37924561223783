import { Fragment, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useAuthenticator } from '@aws-amplify/ui-react';

import { useGetTeamForUserQuery } from '../../features/stats/statsAPI';
import configData from '../../config.json';
import { ReactComponent as FHLIcon } from '../../logo100.svg';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

const FHLAppBar = (props: {title: string, toggleDrawer: any}) => {
    const { signOut } = useAuthenticator((context) => [context.user]);

    const {username} = useAppSelector((state: RootState)=> state.auth);
    const { data: selectedTeam } = useGetTeamForUserQuery({username, season: configData.currentSeason}, {skip: username === ""});
			  
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const logoutAndCloseUserMenu = () => {
      signOut();
      setAnchorElUser(null);
    };
  
    return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.toggleDrawer}
            sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: 'flex' }}>
            <SvgIcon component={FHLIcon} inheritViewBox/>
        </Typography>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, mr: 2, display: 'flex' }}>{props.title}</Typography>
        <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 2, mr: 2, display: 'flex' }}>FHL DASHBOARD</Typography>
        <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={selectedTeam === undefined ? "No Team Yet" : selectedTeam.name} 
                src={selectedTeam === undefined ? "" : "/images/teams/" + selectedTeam.id.toLowerCase() + "100.gif"} />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key='Logout' onClick={logoutAndCloseUserMenu}>
                Logout
                </MenuItem>
            </Menu>
        </Box>
    </Toolbar>
    </AppBar>
)};

interface DrawerProps {
    name: string, 
    link? : string,
    subPages?: {
        name: string,
        link: string
    }[]
};

function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();
  
    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }
  
    return null;
  }

const drawerWidth = 240;

const DrawerMenu = (props: {pages : DrawerProps[], setTitle: any, toggleDrawer?: any}) => {
    const links = props.pages.map((page) => page.link ? [page.link] : page.subPages!.map((subPage) => subPage.link)).flat();
    const routeMatch = useRouteMatch(links);
    return (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        {props.pages.map((page, index) => (
            <Fragment key={index}>
                {index > 0 && <Divider key={(index*10)+9}/>}
                {page.subPages && <ListSubheader key={index*10}>{page.name}</ListSubheader>}
                {page.subPages && page.subPages.map(({name, link}, i) => {
                    const style = link === routeMatch?.pattern?.path ? {color: (theme: any) => theme.palette.primary.main} : {}
                    return (
                    <ListItemButton key={(index*10)+i+1} component={Link} to={link} onClick={()=>{props.setTitle(name); props.toggleDrawer && props.toggleDrawer();}}>
                        <ListItemText sx={style}>{name}</ListItemText>
                    </ListItemButton>
                    );}
                )}
                {page.link && (() => {
                    const style = page.link === routeMatch?.pattern?.path ? {color: (theme: any) => theme.palette.primary.main} : {}
                    return (
                    <ListItemButton key={index*10} component={Link} to={page.link} onClick={()=>{props.setTitle(page.name); props.toggleDrawer && props.toggleDrawer();}}>
                        <ListItemText sx={style}>{page.name}</ListItemText>
                    </ListItemButton>
                    );})()
                }
            </Fragment>
        ))}
      </List>
    </Box>
)};


const FHLDrawer = (props: {pages : DrawerProps[], setTitle: any, isDrawerOpen: boolean, toggleDrawer: any}) => {
 
    return (
    <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm:0}}}>
    <Drawer
        variant="permanent"
        sx={{
        display: { xs: 'none', sm: 'block' },
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        >
        <Toolbar/>
        <DrawerMenu pages={props.pages} setTitle={props.setTitle}/>
    </Drawer>
    <Drawer
        variant="temporary"
        open={props.isDrawerOpen}
        onClose={props.toggleDrawer}
        ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}>
        <Toolbar/>
        <DrawerMenu pages={props.pages} setTitle={props.setTitle} toggleDrawer={props.toggleDrawer}/>
    </Drawer>  
  </Box>
)};

interface NavigationProps {
    children: JSX.Element | JSX.Element[];
    pages: DrawerProps[];
}


export default function FHLNavigation(props : NavigationProps) {
    const [title, setTitle] = useState('Roster');

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <FHLAppBar toggleDrawer={handleDrawerToggle} title={title}/>
            <FHLDrawer pages={props.pages} setTitle={setTitle} toggleDrawer={handleDrawerToggle} isDrawerOpen={mobileOpen}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {props.children}
            </Box>
        </Box>
    );
}
